import React from 'react';
import SearchIcon from '../images/search.svg';
import Logo from '../images/plexticiones-logo.svg';

function SearchBar({query, setQuery, handleKeyPress, username, setUsername}) {
    const handleIconClick = () => {
        handleKeyPress({ key: 'Enter' });
    };

    return (<div className="py-2 mx-4 w-full">
        <div className="md:flex md:justify-between md:items-center text-center md:text-left">
            <a href="/">
                <img src={Logo} alt="Plexticiones Logo" className="logo text-lg text-white md:mr-auto"/>
            </a>
            <div className="mt-2 md:mt-0 md:flex md:gap-4 md:justify-end mx-auto md:mx-0">
                <input type="text" value={username} onChange={(e) => setUsername(e.target.value)}
                       placeholder="Nombre"
                       className="px-2 py-1 rounded-full border border-gray-400 focus:outline-none w-full md:w-1/4 pl-3"/>
                <div className="relative w-full md:w-3/4 mt-2 md:mt-0">
                    <input type="text" value={query} onChange={(e) => setQuery(e.target.value)}
                           onKeyDown={handleKeyPress}
                           placeholder="Título de la película/serie"
                           className="px-2 py-1 rounded-full border border-gray-400 focus:outline-none w-full pl-3"/>
                    <button onClick={handleIconClick} className="absolute right-3 top-1/2 transform -translate-y-1/2 w-5 h-5">
                        <img src={SearchIcon} alt="search"/>
                    </button>
                </div>
            </div>
        </div>
    </div>);
}

export default SearchBar;