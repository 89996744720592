import React, {useState, useRef} from 'react';

function ProgressButton({text, result, handleAdd}) {
    const [isAdded, setIsAdded] = useState(result.isAdded);
    const [state, setState] = useState(result.state);
    const [pressing, setPressing] = useState(false);
    const [pressStart, setPressStart] = useState(0);
    const progressBarRef = useRef(null);

    const handlePressStart = () => {
        // If the button is disabled, return early
        if (isAdded) {
            return;
        }

        setPressing(true);
        setPressStart(Date.now());
        progressBarRef.current.style.transition = 'width 1.5s linear';
        progressBarRef.current.style.width = '100%';
    };

    const handlePressEnd = async () => {
        if (pressing && Date.now() - pressStart >= 1500) {
            if (!isAdded) {
                await handleAdd(result);
                setIsAdded(true);
                setState('pending');
            }
        }
        setPressing(false);
        progressBarRef.current.style.transition = 'none';
        progressBarRef.current.style.width = '0%';
    };

    const stateTextMap = {
        'pending': 'PENDIENTE',
        'not_found': 'NO ENCONTRADO',
        'completed': 'AÑADIDO',
        'default': 'SOLICITAR'
    };

    const getButtonColor = (state) => {
        switch (state) {
            case 'completed':
                return 'bg-green-600 opacity-50 cursor-not-allowed';
            case 'pending':
                return 'bg-yellow-600 opacity-50 cursor-not-allowed';
            case 'not_found':
                return 'bg-red-600 opacity-50 cursor-not-allowed';
            default:
                return 'bg-orange-accent hover:bg-orange-accent';
        }
    };

    return (
        <button
            onMouseDown={handlePressStart}
            onMouseUp={handlePressEnd}
            onTouchStart={handlePressStart}
            onTouchEnd={handlePressEnd}
            onContextMenu={(e) => e.preventDefault()} // Prevent context menu from opening
            disabled={isAdded}
            className={`${getButtonColor(state)} text-gray-900 font-semibold py-1 px-8 rounded mt-3 relative no-select`}
        >
            {isAdded ? stateTextMap[state] : pressing ? 'SOLICITANDO' : text}
            <div ref={progressBarRef} className="progress-bar absolute left-0 top-0 h-full"
                 style={{width: '0%'}}></div>
        </button>
    );
}

export default ProgressButton;