import React from 'react';
import ProgressButton from './ProgressButton';
import imdbLogo from '../images/IMDB_logo.svg';
import Rating from "./Rating";

function SearchResultCard({result, handleAdd}) {
    return (<div
        className="bg-dark-accent rounded-lg overflow-hidden flex max-w-lg mx-auto border-2 border-transparent hover:border-orange-accent w-full h-full max-h-64">
        <img className="w-1/3 object-cover h-full" src={result.Poster} alt={result.Title}/>
        <div className="p-4 w-2/3 flex flex-col justify-between">
            <div>
                <h2 className="text-lg font-medium text-white text-left">{result.Title}</h2>
                <div className="flex flex-wrap items-center info-container">
                    <p className="text-white text-sm border border-white border-opacity-60 p-0.5 px-1 mr-2">{result.Year}</p>
                    <a href={`https://www.imdb.com/title/${result.imdbID}/`} target="_blank" rel="noreferrer">
                        <img src={imdbLogo} alt="IMDb Icon" className="w-10 h-10 inline-block"/>
                    </a>
                    <Rating rating={result.imdbRating} />
                </div>
            </div>
            <ProgressButton
                text='SOLICITAR'
                result={result}
                handleAdd={handleAdd}
            />
        </div>
    </div>);
}

export default SearchResultCard;