import React from 'react';
import SearchResultCard from './SearchResultCard';
import SkeletonCard from "./SkeletonCard";

const isTestingLoading = false;

function SearchResults({isLoading, hasSearched, results, activeTab, handleAdd, fieldsToCheckForNA = []}) {
    return (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10 w-full">
            {isLoading || isTestingLoading ? (<>
                <SkeletonCard />
                <SkeletonCard />
                <SkeletonCard />
                <SkeletonCard />
            </>) : ((() => {
                const filteredResults = results.filter(result => {
                    // Check if the result should be shown based on the active tab
                    const shouldShowBasedOnTab = activeTab === 'Películas' ? result.Type === 'movie' : result.Type === 'series';

                    // Check if the result should be shown based on the fields to check for "N/A"
                    const shouldShowBasedOnFields = fieldsToCheckForNA.every(field => result[field] !== "N/A");

                    return shouldShowBasedOnTab && shouldShowBasedOnFields;
                });
                if (!hasSearched) { // If no search has been performed yet
                    return (<div className="flex justify-center items-center h-full w-full text-white">
                        <p>Realiza una búsqueda para ver los resultados.</p>
                    </div>);
                } else if (filteredResults.length === 0) { // If a search has been performed but no results were found
                    return (<div className="flex justify-center items-center h-full w-full text-white">
                        <p>No se encontraron resultados para el aparatado {activeTab}.</p>
                    </div>);
                } else { // If search results were found
                    return filteredResults.map(result => (<div key={result.imdbID} className="w-full">
                        <SearchResultCard result={result} handleAdd={handleAdd}/>
                    </div>));
                }
            })())}
        </div>
    );
}

export default SearchResults;