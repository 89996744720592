function SkeletonCard() {
    return (<div className="w-full p-4 rounded-lg bg-dark-accent max-w-lg mx-auto h-80 max-h-64">
            <div className="flex space-x-4">
                <div className="bg-dark-bg w-1/3 h-56 rounded animate-pulse"></div>
                <div className="flex flex-col justify-between w-2/3">
                    <div>
                        <div className="bg-dark-bg h-20 w-full mb-2 rounded-xl animate-pulse"></div>
                        <div className="flex flex-row w-2/3">
                            <div className="bg-dark-bg h-6 w-1/3 me-3 rounded animate-pulse"></div>
                            <div className="bg-dark-bg h-6 w-1/3 rounded animate-pulse"></div>
                        </div>
                    </div>
                    <div className="bg-dark-bg h-8 w-full rounded animate-pulse"></div>
                </div>
            </div>
        </div>);
}

export default SkeletonCard;