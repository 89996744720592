import Search from "./components/Search";

function App() {
  return (
    <div className="App">
        <Search />
    </div>
  );
}

export default App;
