import config from "../config";

const KV_REST_API_TOKEN = config.KV_API_TOKEN;
const KV_REST_API_URL = config.KV_API_URL;

async function testFetchEntriesById() {
    // Fetch the ID list
    const idListResponse = await fetch(`${KV_REST_API_URL}/get/id-list`, {
        headers: {
            Authorization: `Bearer ${KV_REST_API_TOKEN}`
        }
    });
    let idListData = await idListResponse.json();
    let idList = idListData.result ? JSON.parse(idListData.result) : [];
    console.log(idList);

    // Fetch and log the information for each ID
    for (const id of idList) {
        const response = await fetch(`${KV_REST_API_URL}/get/${id}`, {
            headers: {
                Authorization: `Bearer ${KV_REST_API_TOKEN}`
            }
        });
        const data = await response.json();
        console.log(JSON.parse(data.result));
    }
}


async function addNewEntry(newEntry) {
    // Fetch the existing ID list
    const idListResponse = await fetch(`${KV_REST_API_URL}/get/id-list`, {
        headers: {
            Authorization: `Bearer ${KV_REST_API_TOKEN}`
        }
    });
    let idListData = await idListResponse.json();
    let idList = idListData.result ? JSON.parse(idListData.result) : [];

    // Check if the new entry's ID is already in the list
    if (!idList.includes(newEntry._id)) {
        // Add the new entry's ID to the list
        idList.push(newEntry._id);

        // Store the updated ID list
        await fetch(`${KV_REST_API_URL}/set/id-list`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${KV_REST_API_TOKEN}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(idList),
        });

        // Store the new entry under its ID
        await fetch(`${KV_REST_API_URL}/set/${newEntry._id}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${KV_REST_API_TOKEN}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(newEntry),
        });
    }
}

async function fetchEntryStateById(id) {
    // Fetch the entry by its ID
    const response = await fetch(`${KV_REST_API_URL}/get/${id}`, {
        headers: {
            Authorization: `Bearer ${KV_REST_API_TOKEN}`
        }
    });
    const data = await response.json();
    const entry = JSON.parse(data.result);

    // Return the state of the entry if it exists, otherwise return null
    return entry ? entry.state : null;
}

async function fetchEntryById(id) {
    // Fetch the entry by its ID
    const response = await fetch(`${KV_REST_API_URL}/get/${id}`, {
        headers: {
            Authorization: `Bearer ${KV_REST_API_TOKEN}`
        }
    });
    const data = await response.json();
    return JSON.parse(data.result);
}

async function clearAllEntries() {
    // Fetch the ID list
    const idListResponse = await fetch(`${KV_REST_API_URL}/get/id-list`, {
        headers: {
            Authorization: `Bearer ${KV_REST_API_TOKEN}`
        }
    });
    let idListData = await idListResponse.json();
    let idList = idListData.result ? JSON.parse(idListData.result) : [];

    // Delete all entries by their IDs
    await Promise.all(idList.map(async id => {
        await fetch(`${KV_REST_API_URL}/del/${id}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${KV_REST_API_TOKEN}`
            }
        });
    }));

    // Clear the ID list
    await fetch(`${KV_REST_API_URL}/del/id-list`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${KV_REST_API_TOKEN}`
        }
    });
}

export {addNewEntry, fetchEntryStateById, testFetchEntriesById, fetchEntryById, clearAllEntries };