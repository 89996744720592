import React from 'react';
import starIcon from '../images/star_icon.svg'; // Replace with the path to your star icon

function Rating({ rating }) {

    if (rating === "N/A") {
        return null;
    }

    return (
        <div className="flex items-center justify-end">
            <img src={starIcon} alt="Star Icon" className="w-4 h-4 ms-2 me-1"/>
            <p className="text-white text-lg mr-1">{rating}</p>
            <p className="text-white text-sm">/ 10</p>
        </div>
    );
}

export default Rating;