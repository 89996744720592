import React, {useState} from 'react';
import { Analytics } from "@vercel/analytics/react"
import SearchBar from './SearchBar';
import SearchResults from './SearchResults';
import FloatingActionButton from './FloatingActionButton';
import {addNewEntry, fetchEntryStateById, fetchEntryById} from '../utils/kv_api';
import config from "../config";

const IMDBOT_BASE_URL = config.IMDBOT_BASE_URL;
const OMDB_BASE_URL = config.OMDB_BASE_URL;
const OMDB_API_KEY = config.OMDB_API_KEY;
const IMDB_BASE_URL = config.IMDB_BASE_URL;

async function fetchMoviesAndTVShows(query) {
    // First, fetch the IMDb ID from the imdbot API
    const imdbResponse = await fetch(`${IMDBOT_BASE_URL}?q=${query}`);
    const imdbData = await imdbResponse.json();

    // Then, use the IMDb ID to fetch detailed information from the OMDb API
    return await Promise.all(imdbData.description.map(async item => {
        const omdbResponse = await fetch(`${OMDB_BASE_URL}?i=${item['#IMDB_ID']}&apikey=${OMDB_API_KEY}`);
        const omdbData = await omdbResponse.json();
        return {
            Title: omdbData.Title,
            Year: omdbData.Year,
            imdbID: omdbData.imdbID,
            Poster: omdbData.Poster,
            imdbRating: omdbData.imdbRating,
            Type: omdbData.Type,
        };
    }));
}

function Search() {
    const [username, setUsername] = useState('');
    const [query, setQuery] = useState('');
    const [results, setResults] = useState([]);
    const [activeTab, setActiveTab] = useState('Películas');
    const [isLoading, setIsLoading] = useState(false);
    const [hasSearched, setHasSearched] = useState(false);
    const [fieldsToCheckForNA] = useState(['Poster', 'Year']);

    const handleFloatingButtonClick = () => {
        // Define what should happen when the floating action button is clicked
    };

    const handleSearch = async () => {
        setIsLoading(true);
        setHasSearched(true);
        try {
            const results = await fetchMoviesAndTVShows(query);
            console.log(results); // TODO: Remove this line
            const allResults = await Promise.all(results.map(async result => {
                const state = await fetchEntryStateById(result.imdbID);
                return {
                    ...result, state: state, isAdded: !!state,
                };
            }));
            setResults(allResults);
        } catch (error) {
            console.error('Error:', error);
        }
        setIsLoading(false);
    };

    function handleKeyPress(event) {
        if (event.key === 'Enter') {
            if (event.preventDefault) {
                event.preventDefault();
            }
            handleSearch();
        }
    };

    const handleAdd = async (result) => {
        const newEntry = {
            _id: result.imdbID,
            username,
            title: result.Title,
            link: `${IMDB_BASE_URL}${result.imdbID}/`,
            request_date: new Date().toISOString().split('T')[0],
            state: 'pending', // Set state to 'pending' for new entries
        };

        try {
            const existingEntry = await fetchEntryById(newEntry._id);

            if (!existingEntry) {
                await addNewEntry(newEntry);
                setResults(results.map(item => item.imdbID === result.imdbID ? {...item, isAdded: true} : item));
            } else {
                setResults(results.map(item => item.imdbID === result.imdbID ? {...item, isAdded: true} : item));
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (

        <div className="w-full overflow-auto">
            <Analytics/>
            <div className="flex items-center justify-center w-full">
                <div className="bg-dark-accent p-2 m-3 rounded-lg flex items-center w-full justify-between">
                    <SearchBar query={query} setQuery={setQuery} handleKeyPress={handleKeyPress} username={username}
                               setUsername={setUsername}/>
                </div>
            </div>
            <div className="flex justify-center space-x-4 mb-6">
                <button onClick={() => setActiveTab('Películas')}
                        className={`px-4 py-2 ${activeTab === 'Películas' ? 'border-b-2 border-orange-accent text-orange-accent' : 'text-white'}`}>Películas
                </button>
                <button onClick={() => setActiveTab('Series')}
                        className={`py-2 px-4 ${activeTab === 'Series' ? 'border-b-2 border-orange-accent text-orange-accent' : 'text-white'}`}>Series
                </button>
            </div>
            <div className="flex justify-center space-x-4 m-3">
                <SearchResults isLoading={isLoading} hasSearched={hasSearched} results={results} activeTab={activeTab}
                               handleAdd={handleAdd} fieldsToCheckForNA={fieldsToCheckForNA}/>
            </div>
            <FloatingActionButton onClick={handleFloatingButtonClick}>
                ?
            </FloatingActionButton>
        </div>);

}

export default Search;